<template>
  <body style="position: relative;">
    <div id="wrap">
      <section>
        <headerLayout></headerLayout>
      </section>
      <main>
        <section class="sub-visual">
          <div class="innWrap">
            <div class="txt">
              <h2>A/S</h2>
              <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
            </div>
            <div class="location">
              <ul>
                <li><a href="#">ORDER</a></li>
                <li><a href="#">서비스 신청</a></li>
                <li><span>A/S</span></li>
              </ul>
            </div>
          </div>
        </section>
        <section class="sub-section svcas">
          <div class="innWrap">
            <div class="lineBox">
              <div class="searchBox-select">
                    <div class="select select-inner w288px" id="svcmodfi-title-01">
                      <span class="placeholder">
												<!-- <span class="fcGy">사업자등록번호</span> -->
												{{this.selectedSearchTypeTitle}}
											</span>
                      <div class="list">
                        <ul v-for="item in searchType" :key="item.typeCd">
                          <li @click="selectValue('searchType', item)">{{item.title}}</li>
                        </ul>
                      </div>
                    </div>
                    <input type="text" id="svcmodfi-title-01" placeholder="입력하세요" class="input-line" v-show="selectedSearchTypeCd === '01'" v-model="partyIdNumber1" maxlength="10" readonly/>
										<input type="text" id="svcmodfi-title-02" placeholder="입력하세요" class="input-line" v-show="selectedSearchTypeCd === '02'" v-model="partyIdNumber2" maxlength="13" readonly/>
                    <a @click="getUserSvcInfo" class="btn-md01 btn-primary">조회</a>
              </div>
              <!-- //form-list -->
              <table class="tbl_type02 checkTbl mgt50">
                <colgroup>
                  <col width="*" />
                  <col width="12%" />
                  <col width="17%" />
                  <col width="17%" />
                  <col width="12%" />
                  <col width="12%" />
                  <col width="25%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>선택</th>
                    <th>고객 ID</th>
                    <th>고객명</th>
                    <th>상품명</th>
                    <th>서비스계약번호</th>
                    <th>회선번호</th>
                    <th>설치장소</th>
                  </tr>
                </thead>
                <tbody>
									<tr v-show="!userSvcInfo">
										<td colspan="7">
											조회된 데이터가 없습니다.
										</td>
									</tr>
                  <tr v-show="userSvcInfo" v-for="item in userSvcInfo" :key="item.scn">
                    <td>
                      <div class="checkbox tbl-ckRadio">
                        <input type="radio" :id="item.scn" class="hide" name="ckRadio" @click="selectValue('scn', item)" />
                        <label :for="item.scn"></label>
                      </div>
                    </td>
                    <td>{{item.custid}}</td>
                    <td>{{item.custname}}</td>
                    <td>{{item.svctypename}}</td>
                    <td>{{item.scn}}</td>
                    <td>{{item.llnum}}</td>
                    <td>{{item.addr}}</td>
                  </tr>
                </tbody>
              </table>

              <h3 class="sLtitle">
                A/S 신청<span class="required">*필수 입력</span>
              </h3>
              <div class="lineBox con03 form-list">
                <div class="form-Item item01">
                  <label for="ascon-01">고객명</label>
                  <div class="fcon" style="margin-top: 10px;">
                    {{custNm}}
                  </div>
                </div>
                <div class="form-Item item02">
                  <label for="ascon-02">A/S유형<span class="required">*</span></label>
                  <div class="fcon">
                    <div class="select" id="ascon-02">
                      <span class="placeholder"
                        ><span class="fcGy">유형을 선택하세요</span></span
                      >
                      <div class="list">
                        <ul v-for="item in asCodeList" :key="item.code_cd" @click="selectValue('as', item)">
                          <li>{{item.code_nm}}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-Item item03">
                  <label for="ascon-03">문의내용<span class="required">*</span></label>
                  <div class="fcon">
                    <div class="text-box">
                      <textarea id="ascon-03" placeholder="상세 문의 내역 기입 (1000자 까지 입력 가능)" v-model="reporterOpinion" ref="reporterOpinion"></textarea>
                      <div class="count">
                        <div><span>0</span>/1000</div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <!-- <div class="form-Item item04">
                  <label for="ascon-04">연락처</label>
                  <div class="fcon">
                    <input
                      type="text"
                      id="ascon-04"
                      placeholder="연락처를 입력해주세요 (-제외)"
											v-model="custTelNum"
                    />
                  </div>
                </div> -->
                <p class="asterisk_bk pdl320">
                  ※ A/S 전문 상담사가 연락드릴 예정이며, 상담으로 미해결 시
                  전문기사님이 방문 출동 조치 예정입니다.<br />
                  ※ 고객 사유에 의한 기사 방문 시 출동비 (부가세 포함 55,000원)
                  이 청구될 수 있습니다.
                </p>
                <div class="btnBottomArea">
                  <a @click="getGoSaOrderAply" class="btn-md01  fix btn-primary">신청</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <section>
        <footerLayout></footerLayout>
      </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from "./common/Header.vue";
import FooterLayout from "./common/Footer.vue";
import { gigaofficeService } from 'Api'
export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data() {
    return {
			searchType: [
				{ title: '사업자등록번호', typeCd: '01'},
				{ title: '법인번호', typeCd: '02'}
			],
			selectedSearchTypeCd: '01',
			selectedSearchTypeTitle: '사업자등록번호',
			selectedAsTypeCd: '',
      selectedValue: '',
			partyIdNumber1: '',
			partyIdNumber2: '',
			encPartyIdNumber1: '',
			encPartyIdNumber2: '',
			userSvcInfo: '',
			custType: '',
			custNum: '',
      custNm: sessionStorage.getItem("userName"),
			custTelNum:
        sessionStorage.getItem("mobileNumber") === "null"
          ? sessionStorage.getItem("phoneNumber")
          : sessionStorage.getItem("mobileNumber"),
			asCodeList: '',
			reporterOpinion: '',
    };
  },
  created: function() {
		this.getOwnerContactInfoByTokenId()
		},
  mounted: function() {
		setTimeout(() => {
			this.getComCodeList('AS')
		}, 100);
	},
  methods: {
		selectValue(type, obj) {
			if(type==='searchType') {
				this.selectedSearchTypeCd = obj.typeCd
				this.selectedSearchTypeTitle = obj.title
			} else if(type==='as') {
				this.selectedAsTypeCd = obj.code_cd;
			} else {
        this.selectedValue = obj
        this.svcType = obj.svctypename
      }
    },
		async getComCodeList(groupCd) {
			let param = {
				"token_id" : sessionStorage.getItem("accessToken"),
				"group_cd" : groupCd /* 그룹 코드 값 (필수)  (예) 고장 신고 사유  */
				// "code_cd" : "R1", /* 선택적으로 필요할 때만 사용하세요 */    
			}
			let res = await gigaofficeService.getComCodeList(param)
			// console.log("ascode::")
			this.asCodeList = res.data.response.results
			// console.log(res.data.response.results)
		},
    //1-1. 토큰으로 사업자번호/법인번호 조회
		async getOwnerContactInfoByTokenId() {
			// let tokenId = sessionStorage.getItem('accessToken')
			//TODO TEST tokenId
			// let tokenId = '081d0f51f3f1a1d3e0a817e1951fc22c4eacc3c052f4849dd75aeb3be845407ce4bb060513d86b0baf36b86c95a0281d'
			let token_id = sessionStorage.getItem('accessToken')
			let param = {token_id: token_id}
			await this.$store.dispatch("auth/getOwnerContactInfoByTokenId", param).then((res) => {
				// console.log(res)
				if(res != null) {
					this.ownerContactInfo = res
					if(res.partyIdNumber1 != null && res.partyIdNumber1 != '') { 
						this.partyIdNumber1 = res.partyIdNumber1
						this.encPartyIdNumber1 = res.encPartyIdNumber1
					}
					if(res.partyIdNumber2 != null && res.partyIdNumber2 != '') {
						this.partyIdNumber2 = res.partyIdNumber2
						this.encPartyIdNumber2 = res.encPartyIdNumber2
					}
				}
			})
			.catch(error => {
				console.log(error);
			});
		},
		//2. 사업자/법인번호에 대한 고객의 전용번호/상품내역 조회
		async getUserSvcInfo() {
			if(this.partyIdNumber == '01' && (this.partyIdNumber1 == '' || this.partyIdNumber1 == null)) {
				alert('조회 정보가 입력 되지 않았습니다. 사업자등록번호 또는 법인번호를 입력해주세요.')
				return false
			}
			if(this.partyIdNumber == '02' && (this.partyIdNumber2 == '' || this.partyIdNumber2 == null)) {
				alert('조회 정보가 입력 되지 않았습니다. 사업자등록번호 또는 법인번호를 입력해주세요.')
				return false
			}

      let param = {} ,res1 ,res2 ,temp1 ,temp2 ,temp = [] ,key = 'scn';
      //사업자등록번호 (10자리) / 법인번호 (13자리)
      this.selectedSearchTypeCd == "8" ? (this.custType = "8") : (this.custType = "3");
      this.selectedSearchTypeCd == "8" ? (this.custNum = this.encPartyIdNumber1) : (this.custNum = this.encPartyIdNumber2);
      param = { custType: this.custType, custNum: this.custNum };
      //api호출
      res1 = await gigaofficeService.getUserSvcInfo(param);
      temp1 = res1.data.result

      this.selectedSearchTypeCd == "8" ? (this.custType = "3") : (this.custType = "8");
      this.selectedSearchTypeCd == "8" ? (this.custNum = this.encPartyIdNumber1) : (this.custNum = this.encPartyIdNumber2);
      param = { custType: this.custType, custNum: this.custNum };
      res2 = await gigaofficeService.getUserSvcInfo(param);
      temp2 = res2.data.result

      if(temp1 !== undefined && temp1.length > 0){
          temp1.forEach(function(value ,index){
            temp.push(value)
          })
      }
      if(temp2 !== undefined && temp2.length > 0){
          temp2.forEach(function(value ,index){
            temp.push(value)
          })
      }
      temp = temp.reduce((i ,item) => {
        const removed = i.filter(j => j[key] !== item[key])
        return [...removed ,item]
      }, [])

      let count = temp.length
      for(var i = 0; i < count; i++) {
        temp[i].addr = temp[i].addr+temp[i].addrnum+temp[i].addrsubnum+temp[i].detailaddr
      }
      this.userSvcInfo = temp

      // console.log('this.userSvcInfo : ', this.userSvcInfo)

      // //사업자등록번호 (10자리) / 법인번호 (13자리)
			// this.selectedSearchTypeCd === '01' ? this.custType = '8' : this.custType = '3'
			// this.selectedSearchTypeCd === '01' ? this.custNum = this.partyIdNumber1.replace(/-/gi,'') : this.custNum = this.partyIdNumber2.replace(/-/gi,'')
			// //TODO test data
			// // this.custType = this.partyIdNumber == '01' ? '8' : '3'
			// // this.custNum = this.partyIdNumber == '01' ? this.partyIdNumber1.replace(/-/gi,'') : this.partyIdNumber2.replace('-','')
			
			// let param = {'custType': this.custType, 'custNum': this.custNum}
			// //api호출
			// let res = await gigaofficeService.getUserSvcInfo(param)
			// if(res.data.code == '100') {
			// 	var count = res.data.count
			// 	var result = res.data.result
			// 	if(res.data.count > 0) {
			// 		this.userSvcInfo = result
			// 		console.log(this.userSvcInfo)
			// 		for(var i = 0; i < count; i++) {
			// 			this.userSvcInfo[i].addr = result[i].addr+result[i].addrnum+result[i].addrsubnum+result[i].detailaddr
			// 		}
			// 		// this.bizr_rgst_no = this.partyIdNumber1
			// 		// this.crpr_no = this.partyIdNumber2
			// 	}
			// } else {
			// 	alert('조회 중 오류가 발생했습니다.\n error : '+res.data.code)
			// }
		},
		//AS 신청
		async getGoSaOrderAply() {
      // console.log(this.svcType)
      if(!confirm('A/S를 신청하시겠습니까?')) {
        return false
      }
      if(!this.selectedValue) {
        alert('A/S신청하실 정보를 선택해주세요.')
        return false
      }
      if(!this.selectedAsTypeCd) {
        alert('A/S유형을 선택해주세요.')
        return false
      }
      if(!this.reporterOpinion) {
        alert('문의내용을 입력해주세요.')
        this.$refs.reporterOpinion.focus()
        return false
      }
			let param = {
				"token_id" : sessionStorage.getItem("accessToken"),
				"scn" : this.selectedValue.encScn,
				// "troubSvcTypeCode" : this.svcType.substr(0, 1) == 'F' ? 'VN' : 'KO',
				"troubSvcTypeCode" : this.svcType.toLowerCase().includes('basic') ? 'VN' : 'KO',
				"reporterName" : this.custNm,
        "asTypeCode" : this.selectedAsTypeCd,
				// "reporterTelNum" : ''this.custTelNum'',
				// "reporterHpNum" : this.custTelNum,
				"reporterOpinion" : this.reporterOpinion
			}
      // console.log(param)
      const res = await gigaofficeService.getGoSaOrderAply(param)
      if(res.data.resCode == '0') {
        alert('A/S신청이 완료되었습니다.')
        window.location.reload()
      } else {
				alert('신청중 오류가 발생했습니다.\n error : '+res.data.resDesc)
      }
		}
	}
}
</script>
